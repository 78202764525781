import styled from "styled-components";
import { Color, Widths } from "../../../../styles";

export const Wrapper = styled.div`
  max-width: 1198px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.75rem;
  margin-bottom: 2rem;
  background-color: ${Color.lightGray};

  @media screen and (min-width: ${Widths.minTablet}) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 5rem;
  }
`;

export const WrapCopy = styled.div`
  padding: 0 1.5rem 2rem 1.5rem;

  h2 {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${Widths.minLaptop}) {
    padding: 0 5rem 0 0;

    h2 {
      font-size: 3.5em;
      line-height: 40px;
    }
  }
`;

export const Copy = styled.div`
  p {
    font-size: 1rem;
    line-height: 22px;
  }
`;
