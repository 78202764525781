import React from "react";
import PageHero from "./modules/PageHero/PageHero";
import CategoryTiles from "./modules/CategoryTiles/CategoryTiles";
import ProductsSlider from "./modules/ProductSlider/ProductSlider";
import TwoColumnContent from "./modules/TwoColumnContent/TwoColumnContent";
import ThreeColumnContent from "./modules/ThreeColumnContent/ThreeColumnContent";
import ContentAndSidebar from "./modules/ContentAndSidebar/ContentAndSidebar";
import IconGrid from "./modules/IconGrid/IconGrid";
import SubscribeToNewsletter from "./modules/SubscribeToNewsletter/SubscribeToNewsletter";
import Banner from "./modules/Banner/Banner";
import ContactForm from "./modules/ContactForm/ContactForm";
import CalculatorModule from "./modules/Calculator/CalculatorModule";
import GalleryModule from "./modules/Gallery/GalleryModule";
import Map from "./modules/Map/Map";
import ConstantContactModule from "./modules/ConstantContact/ConstantContactModule";
import FeatureHighlights from "./modules/FeatureHighlights/FeatureHighlights";
import SplitContent from "./modules/SplitContent/SplitContent";
import ServicesSummary from "./modules/ServicesSummary/ServicesSummary";
import LatestPosts from "./modules/LatestPosts/LatestPosts";
import Separator from "./modules/Separator/Separator";
import RichTextWithBackground from "./modules/RichTextWithBackground/RichTextWithBackground";
import SideBySide from "./modules/SideBySide/SideBySide";
import ImagesList from "./modules/ImagesList/ImagesList";
import ProductGrid from "./modules/ProductGrid/ProductGrid";
import CollapsedContent from "./modules/CollapsedContent/CollapsedContent";
import QuoteSlider from "./modules/QuoteSlider/QuoteSlider";

export default function ModuleFactory({ module }) {
  let moduleComponent = null;
  switch (module.section_name) {
    case "hero":
      // image
      // heading
      // cta
      moduleComponent = (
        <PageHero
          mediaType={module.media_type}
          image={module.image}
          video={module.video}
          heading={module.heading}
          headingColor={module.heading_color}
          copy={module.copy}
          copyColor={module.copy_color}
          cta={module.cta}
          ctaColor={module.cta_color}
          ctaAlt={module.cta_alt}
          ctaColorAlt={module.cta_color_alt}
        />
      );
      break;

    case "separator":
      moduleComponent = <Separator margin={module.margin} />;
      break;

    case "feature_highlights":
      moduleComponent = (
        <FeatureHighlights
          heading={module.heading}
          features={module.features}
        />
      );
      break;

    case "split_content":
      moduleComponent = (
        <SplitContent
          title={module.title}
          copy={module.copy}
          image={module.image}
        />
      );
      break;

    case "collapsed_content":
      moduleComponent = (
        <CollapsedContent
          title={module.title}
          copy={module.copy}
          image={module.image}
        />
      );
      break;

    case "side_by_side":
      moduleComponent = (
        <SideBySide
          title={module.title}
          copy={module.copy}
          image={module.image}
          cta={module.cta}
          orientation={module.orientation}
        />
      );
      break;

    case "services_summary":
      moduleComponent = (
        <ServicesSummary heading={module.heading} services={module.services} />
      );
      break;

    case "rich_text_with_background":
      moduleComponent = (
        <RichTextWithBackground
          bgColor={module.background_color}
          copy={module.copy}
          cta={module.cta}
          reduceMargins={module.reduce_margins}
        />
      );
      break;

    case "images_list":
      moduleComponent = (
        <ImagesList copy={module.copy} images={module.images} />
      );
      break;

    case "quote_slider":
      moduleComponent = (
        <QuoteSlider title={module.title} quotes={module.quotes} />
      );
      break;

    case "latest_posts":
      moduleComponent = (
        <LatestPosts title={module.title} posts={module.posts} />
      );
      break;

    case "product_category_tiles":
      // categories
      // tiles_per_row
      moduleComponent = (
        <CategoryTiles
          categoryIds={module.categories}
          tilesPerRow={module.tiles_per_row}
        />
      );
      break;

    case "product_slider":
      // title
      // products
      moduleComponent = (
        <ProductsSlider title={module.title} productTypes={module.products} />
      );
      break;

    case "product_grid":
      // title
      // products
      moduleComponent = (
        <ProductGrid
          title={module.title}
          productTypes={module.products}
          useShortDescription={module.use_short_description}
        />
      );
      break;

    case "2_column_content":
      // left
      // right
      moduleComponent = (
        <TwoColumnContent left={module.left} right={module.right} />
      );
      break;

    case "3_column_content":
      // theme
      // title
      // first
      // second
      // third
      moduleComponent = (
        <ThreeColumnContent
          theme={module.theme}
          title={module.title}
          first={module.first}
          second={module.second}
          third={module.third}
          reduceMargins={module.reduce_margins}
          centerTitle={module.center_title}
        />
      );
      break;

    case "content_and_sidebar":
      // sidebar_position
      // content
      // sidebar
      moduleComponent = (
        <ContentAndSidebar
          sidebarPosition={module.sidebar_position}
          content={module.content}
          sidebarModules={module.sidebar}
        />
      );
      break;

    case "icon_grid":
      // icons_title
      // icons
      moduleComponent = (
        <IconGrid title={module.icons_title} icons={module.icons} />
      );
      break;

    case "banner":
      // image
      // text
      // cta
      moduleComponent = (
        <Banner
          image={module.image}
          textAlign={module.text_align}
          text={module.text}
          cta={module.cta}
        />
      );
      break;

    case "subscribe_to_newsletter":
      // headline
      // title
      moduleComponent = (
        <SubscribeToNewsletter
          headline={module.headline}
          title={module.title}
        />
      );
      break;

    case "contact_form":
      // title
      moduleComponent = <ContactForm title={module.title} />;
      break;

    case "coverage_calculator":
      // title
      moduleComponent = <CalculatorModule title={module.title} />;
      break;

    case "gallery":
      // title
      moduleComponent = (
        <GalleryModule title={module.title} items={module.items} />
      );
      break;

    case "map":
      // title
      // address
      // show_details
      // phone
      // fax
      moduleComponent = (
        <Map
          title={module.title}
          address={module.address}
          showDetails={module.show_details}
          phone={module.phone}
          fax={module.fax}
          map={module.map}
        />
      );
      break;

    case "constant_contact":
      moduleComponent = (
        <ConstantContactModule dataFormId={module.data_form_id} />
      );
      break;

    default:
      moduleComponent = null;
      break;
  }
  return moduleComponent;
}
