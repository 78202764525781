import styled from "styled-components";

import {
  Color,
  Padding,
  Font,
  FontSize,
  FontWeight,
  LineHeight,
  Widths,
  Block,
  restrictedWidth,
} from "../../../styles";

export const Container = styled(Block)`
  ${restrictedWidth}
  padding-top: 2em;
  padding-bottom: 2em;

  @media screen and (min-width: ${Widths.minLaptop}) {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
`;

export const ColWrapper = styled(Block).attrs((props) => ({
  className: `ColWrapper`,
  currDevice: props.currDevice || "desktop",
}))`
  display: flex;
  gap: 2rem;
  flex-direction: ${(props) =>
    "desktop" === props.currDevice || "laptop" === props.currDevice
      ? `row`
      : `column`};
`;

export const Col = styled(Block).attrs((props) => ({
  className: `Col`,
  currDevice: props.currDevice || "desktop",
}))`
  position: relative;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  background-color: ${Color.blue};

  .footer-widget-col {
    ${(props) => "mobile" !== props.currDevice && `margin-bottom: 2.375rem`};

    > h2 {
      color: ${Color.lightBlue};
      font: ${Font.tertiary};
      font-weight: ${FontWeight.bold};
      font-size: ${FontSize.footer.menuHeading};
      text-transform: uppercase;
      margin-bottom: 4px;
      ${(props) =>
        "mobile" === props.currDevice && `margin-top: ${Padding.padding1_5}`};
    }
    > div {
      ul {
        list-style: none;
        margin: 0 1em 0 0;

        li {
          padding: 0.5em 0;

          a {
            color: ${Color.white};
            font-family: ${Font.primary};
            font-weight: ${FontWeight.regular};
            font-size: ${FontSize.footer.menuItem.mobile};
            line-height: ${LineHeight.regular};
            text-decoration: none;

            @media screen and (min-width: ${Widths.minTablet}) {
              font-size: ${FontSize.footer.menuItem.tablet};
              line-height: ${LineHeight.one};
            }
          }
        }
      }
    }
  }
`;
