import React from "react";
import { Wrapper } from "./styles";
import { getLinkUrl } from "../../../Helpers";
import { AnchorButton } from "../../../../styles";
import { useHistory } from "react-router-dom";

export default function RichTextWithBackground({
  bgColor,
  copy,
  cta,
  reduceMargins,
}) {
  const router = useHistory();
  const newAtts = getLinkUrl((cta && cta.url) || "");

  const renderCTA = () => {
    if (cta && newAtts) {
      return (
        <AnchorButton
          onClick={() =>
            newAtts.external
              ? (window.location = newAtts.href)
              : router.push(newAtts.as)
          }
          color={`white`}
          borderColor={`white`}
          bgColor={`red`}
          hoverColor={`white`}
          hoverBorderColor={`black`}
          hoverBgColor={`black`}
          maxWidth={`none`}
          width={`auto`}
          padding={`1em 1.5em`}
        >
          {cta.title}
        </AnchorButton>
      );
    }
  };

  return (
    <Wrapper bgColor={bgColor} reduceMargins={reduceMargins}>
      <div dangerouslySetInnerHTML={{ __html: copy }} />
      {renderCTA()}
    </Wrapper>
  );
}
