import styled from "styled-components";

import {
  Block,
  FontWeight,
  Color,
} from '../../../../styles'

export const TextContainer = styled(Block)`
  background-color: ${props => props.bgColor};
  box-sizing: border-box;
  padding: ${props => 'mobile' === props.currDevice ? `2em` : `2.3vw 1.5vw 2.1vw`};
  margin-bottom: 1em;
  border-radius: 8px;

  h1,
  h2,
  h3
  {
    font-weight: ${FontWeight.bold};
    font-size: 2.1em;
    margin-bottom: 1.5em;
  }

  h4,
  h5,
  h6 {
    font-weight: ${FontWeight.semiBold};
    margin-bottom: 0;
    font-size: 1.4em;
    line-height: 1.35em;
    margin-bottom: 0.2em;
  }

  p {
    font-size: 1.4em;
    line-height: 1.43em;
    margin-bottom: 1.4em;
  }

  a {
    color: ${Color.red} !important;
  }
`
