import React, { useState } from "react";
import { formConfig } from './ContactForm.config'
import ContactFormAPIClient from '../../../../api/clients/contactForm'
import {
  Color,
  Padding,
  ContentContainer,
  Heading,
  FormContainer,
  // InputReqLabel,
  Button,
  MailSuccess,
  MailError,
  ReqMessage,
} from '../../../../styles'
import {
  ContactInputLabel,
  ContactInputText,
  ContactInputTextArea,
} from './styles'
import {
  validateEmail,
  useLocation
} from '../../../Helpers'

export default function ContactForm({ title }) {
  // state
  const [mailSent, setMailSent] = useState(false)
  const [mailError, setMailError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formInputFirstName, setFormInputFirstName] = useState('')
  const [formInputLastName, setFormInputLastName] = useState('')
  const [formInputTitle, setFormInputTitle] = useState('')
  const [formInputPhone, setFormInputPhone] = useState('')
  const [formInputEmail, setFormInputEmail] = useState('')
  const [formInputMessage, setFormInputMessage] = useState('')

  const location = useLocation()
  // vars
  // const reqText = '(Required)';
  const inputBorderStyle = `1px solid ${Color.gray}`;
  const inputMarginStyle = `0 0 2% 0`;

  const handleFormSubmission = e => {
    // disable btn
    setIsLoading(true)
    // Prevent the default action
    e.preventDefault();

    // validate email
    const formEmailValid = validateEmail(formInputEmail)

    if (
      '' !== formInputFirstName
      && '' !== formInputLastName
      && '' !== formInputTitle
      && '' !== formInputEmail
      && '' !== formInputPhone
      && '' !== formInputMessage
      && false !== formEmailValid
    ) {
      submitForm({
        toClient: formInputEmail,
        subject: 'Online Enquiry',
        bodyClient: `Hi,<br><br>Thank you for your enquiry via Quam Speakers. We have included the enquiry below for your convenience.<br><br><b>First Name:</b> ${formInputFirstName}<br><b>Last Name:</b> ${formInputLastName}<br><b>Title:</b> ${formInputTitle}<br><b>Email:</b> ${formInputEmail}<br><b>Phone:</b> ${formInputPhone}<br><b>Message:</b> ${formInputMessage}`,
        bodyAdmin: `Hi,<br><br>You have just received a new web enquiry via Quam Speakers.<br><br><b>First Name:</b> ${formInputFirstName}<br><b>Last Name:</b> ${formInputLastName}<br><b>Title:</b> ${formInputTitle}<br><b>Email:</b> ${formInputEmail}<br><b>Phone:</b> ${formInputPhone}<br><b>Message:</b> ${formInputMessage}`,
        url: location.href,
      })
    }
    else {
      setMailSent(false)
      setMailError(true)
      setIsLoading(false)
    }
  }

  const submitForm = async (formData) => {
    try {
      const API = new ContactFormAPIClient()
      const response = await API.submitContactForm(formData)
      // handle response if successful
      if (true === response.success) {
        setMailSent(true)
        setMailError(false)
      }
      setIsLoading(false)
    } catch(err) {
      console.error(err)
      setMailSent(false)
      setMailError(true)
      setIsLoading(false)
    }
  }

  return (<ContentContainer>
    <Heading
      type={'h4'}
      children={title}
      color={`blue`}
      fontSize={`3.2em`}
      tTransform={`capitalize`}
    />

    <FormContainer
      action={'#'}
      padding={`${Padding.padding1} 0`}
    >
      <ReqMessage
        size={`1.5em`}
        color={`gray`}
        // margin={`0 auto -1vw 0`}
      >
        {'All fields are required.'}
      </ReqMessage>
      <ContactInputLabel>
        {formConfig.form_fields.firstName.label}
        {/* {formConfig.form_fields.firstName.required
          && <InputReqLabel>{reqText}</InputReqLabel>} */}
      </ContactInputLabel>
      <ContactInputText
        name={formConfig.form_fields.firstName.name}
        type={'text'}
        value={formInputFirstName}
        placeholder={formConfig.form_fields.firstName.label}
        required={true}
        onChange={e => setFormInputFirstName(e.target.value)}
        margin={inputMarginStyle}
        border={inputBorderStyle}
      />
      <ContactInputLabel>
        {formConfig.form_fields.lastName.label}
        {/* {formConfig.form_fields.lastName.required
          && <InputReqLabel>{reqText}</InputReqLabel>} */}
      </ContactInputLabel>
      <ContactInputText
        name={formConfig.form_fields.lastName.name}
        type={'text'}
        value={formInputLastName}
        placeholder={formConfig.form_fields.lastName.label}
        required={true}
        onChange={e => setFormInputLastName(e.target.value)}
        margin={inputMarginStyle}
        border={inputBorderStyle}
      />
      <ContactInputLabel>
        {formConfig.form_fields.title.label}
        {/* {formConfig.form_fields.title.required
          && <InputReqLabel>{reqText}</InputReqLabel>} */}
      </ContactInputLabel>
      <ContactInputText
        name={formConfig.form_fields.title.name}
        type={'text'}
        value={formInputTitle}
        placeholder={formConfig.form_fields.title.label}
        required={true}
        onChange={e => setFormInputTitle(e.target.value)}
        margin={inputMarginStyle}
        border={inputBorderStyle}
      />
      <ContactInputLabel>
        {formConfig.form_fields.email.label}
        {/* {formConfig.form_fields.email.required
          && <InputReqLabel>{reqText}</InputReqLabel>} */}
      </ContactInputLabel>
      <ContactInputText
        name={formConfig.form_fields.email.name}
        type={'email'}
        value={formInputEmail}
        placeholder={formConfig.form_fields.email.label}
        required={true}
        onChange={e => setFormInputEmail(e.target.value)}
        margin={inputMarginStyle}
        border={inputBorderStyle}
      />
      <ContactInputLabel>
        {formConfig.form_fields.phone.label}
        {/* {formConfig.form_fields.phone.required
          && <InputReqLabel>{reqText}</InputReqLabel>} */}
      </ContactInputLabel>
      <ContactInputText
        name={formConfig.form_fields.phone.name}
        type={'phone'}
        value={formInputPhone}
        placeholder={formConfig.form_fields.phone.label}
        required={true}
        onChange={e => setFormInputPhone(e.target.value)}
        margin={inputMarginStyle}
        border={inputBorderStyle}
      />
      <ContactInputLabel>
        {formConfig.form_fields.message.label}
        {/* {formConfig.form_fields.message.required
          && <InputReqLabel>{reqText}</InputReqLabel>} */}
      </ContactInputLabel>
      <ContactInputTextArea
        name={formConfig.form_fields.message.name}
        value={formInputMessage}
        placeholder={formConfig.form_fields.message.label}
        required={true}
        onChange={e => setFormInputMessage(e.target.value)}
        rows={10}
        margin={inputMarginStyle}
        border={inputBorderStyle}
      />
      {mailError === true &&
        <MailError>
          {formConfig.mailError}
        </MailError>
      }
      {mailSent === true &&
        <MailSuccess>
          {formConfig.mailSent}
        </MailSuccess>
      }
      <Button
        margin={`2em auto 0 0`}
        type={'submit'}
        onClick={(e) => handleFormSubmission(e)}
        disabled={isLoading}
      >
        {'Send'}
      </Button>
    </FormContainer>
  </ContentContainer>);
}
