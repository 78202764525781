import React from 'react'
import ProductsSlider from '../Modules/modules/ProductSlider/ProductSlider';

export default function FooterModuleFactory({ module }) {
  let moduleComponent = null;
  switch (module.section_name) {
    case "product_slider":
        moduleComponent = <ProductsSlider title={module.title} productTypes={module.products} />
      break;
      
    default:
      break
  }
  return moduleComponent;
}

