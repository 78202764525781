import React from "react";
import styled from 'styled-components'
import {getColorByName} from './helpers'

import {
  outputImgAlignment,
} from './helpers'

import {
  Color,
  Font,
  FontWeight,
  FontSize,
  Widths,
  restrictedWidth,
} from './global'

import   {
  Span,
} from './typography'

export const Block = styled.div.attrs(props => ({
  inline: props.inline || false,
  align: props.align || `center`,
  vAlign: props.vAlign || `middle`,
  tAlign: props.tAlign || false,
  fontSize: props.fontSize || `inherit`,
  padding: props.padding || `0`,
  className: `Block ${props.className || ''}`,
  currDevice: props.currDevice || 'desktop'
}))`
  display: ${props => props.inline && `inline-`}block;

  ${props => (props.inline)
      && `vertical-align: ${props.vAlign};`}

  font-family: ${Font.primary};
  font-size: ${props => props.fontSize};
  text-align: ${props => props.tAlign
    ? props.tAlign
    : `inherit`};
  color: ${Color.black};

  padding: ${props => props.padding};
`

export const ContentContainer = styled(Block).attrs(props => ({
  bgColor: props.bgColor || `white`,
  padding: props.padding || `0`,
  className: `ContentContainer ${props.className || ''}`
}))`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: ${props => props.padding};
  background-color: ${props => getColorByName(props.bgColor)};
`;

export const ContentWrapper = styled(Block).attrs(props => ({
  currDevice: props.currDevice || 'desktop',
  minHeight: props.minHeight || `unset`,
  className: `ContentWrapper ${props.className || ''}`
}))`
  ${restrictedWidth}
  min-height: ${props => props.minHeight};
  margin-left: 6vw;
  margin-right: 6vw;

  @media screen and (min-width: ${Widths.minLaptop}) {
    margin: 0 auto;
  }
`;

export const Image = styled.img.attrs(props => ({
  dataWidth: props.width || `100%`,
  dataHeight: props.height || `auto`,
  inline: props.inline || false,
  align: props.align || `center`,
  className: `Image ${props.className || ''}`
}))`
  display: ${props => props.inline && `inline-`}block;
  max-width: ${props => props.dataWidth};
  height: ${props => props.dataHeight};
  border: 0; /*for IE*/

  ${props => outputImgAlignment(props.align)}
`

export const Anchor = styled.a.attrs(props => ({
  inline: props.inline || false,
  color: props.color || Color.gray,
  className: `Anchor ${props.className || ''}`
}))`
  display: ${props => props.inline ? `inline-block` : `inline`};
  text-decoration: none;
  text-align: inherit;
  color: ${props => props.color};
  font-size: inherit;
  cursor: pointer;
`

const btnSvgStyles = `
  position: absolute;
  left: calc(${Widths.icons.small} / 2);
`

export const ButtonMain = styled.button.attrs(props => ({
  padding: props.padding || `1em 0.5em`,
  margin: props.margin || `0`,
  fontSize: props.fontSize || FontSize.button.large,
  maxWidth: props.maxWidth || Widths.maxBtn,
  className: `Button ${props.className || ''}`,
  icon: props.icon || null
}))`
  position: relative;
  display: inline-block;
  font-family: ${Font.primary};
  font-size: ${props => props.fontSize};
  font-weight: ${FontWeight.regular};
  text-align: center;
  box-sizing: border-box;
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  border: 1px solid;
  cursor: pointer;
  white-space: normal;
  width: 100%;
  max-width: ${props => props.maxWidth};
  transition: 0.2s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  @media screen and (min-width: ${Widths.minTablet}) {
    ${props => props.icon && !(props.icon.includes('.svg') || props.icon.includes('image/svg'))
      ? `position: relative;
         padding-left: calc(1em + 25px);
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0.9em;
            transform: translateY(-50%);
            width: 25px;
            height: 25px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(${props.icon});
          }
          &:hover {
            &:before {
              -webkit-filter: invert(100%);
            }
          }`
      : ``}
  }
`

export const Button = styled(ButtonMain).attrs(props => ({
  color: props.color || `white`,
  bgColor: props.bgColor || `red`,
  borderColor: props.borderColor || `red`,
  hoverColor: props.hoverColor || `red`,
  hoverBgColor: props.hoverBgColor || `white`,
  hoverBorderColor: props.hoverBorderColor || `red`,
}))`
  color: ${props => getColorByName(props.color)};
  background-color: ${props => getColorByName(props.bgColor)};
  border-color: ${props => getColorByName(props.borderColor)};
  min-height: 61px;

  .SVGContainer {
    ${btnSvgStyles}

    top: ${props => props.top ? props.top : 'initial' };

    svg {
      fill: ${props => getColorByName(props.color)};
      stroke: ${props => getColorByName(props.color)};

      path,
      text,
      polygon
      {
        fill: ${props => getColorByName(props.color)};
        stroke: ${props => getColorByName(props.color)};
      }
    }
  }

  &:hover {
    color: ${props => getColorByName(props.hoverColor)};
    background-color: ${props => getColorByName(props.hoverBgColor)};
    border-color: ${props => getColorByName(props.hoverBorderColor)};

    svg {
      fill: ${props => getColorByName(props.hoverColor)};
      stroke: ${props => getColorByName(props.hoverColor)};

      path,
      text,
      polygon
      {
        fill: ${props => getColorByName(props.hoverColor)};
        stroke: ${props => getColorByName(props.hoverColor)};
      }
    }
  }
`

export const ButtonAlt = styled(ButtonMain).attrs(props => ({
  color: props.color || `black`,
  bgColor: props.bgColor || `transparent`,
  borderColor: props.borderColor || `black`,
  hoverColor: props.hoverColor || `white`,
  hoverBgColor: props.hoverBgColor || `black`,
  hoverBorderColor: props.hoverBorderColor || `black`,
}))`
  color: ${props => getColorByName(props.color)};
  background-color: ${props => getColorByName(props.bgColor)};
  border-color: ${props => getColorByName(props.borderColor)};

  .SVGContainer {
    ${btnSvgStyles}

    svg {
      fill: ${props => getColorByName(props.color)};
      stroke: ${props => getColorByName(props.color)};

      path,
      text,
      polygon
      {
        fill: ${props => getColorByName(props.color)};
        stroke: ${props => getColorByName(props.color)};
      }
    }
  }

  &:hover {
    color: ${props => getColorByName(props.hoverColor)};
    background-color: ${props => getColorByName(props.hoverBgColor)};
    border-color: ${props => getColorByName(props.hoverBorderColor)};

    svg {
      fill: ${props => getColorByName(props.hoverColor)};
      stroke: ${props => getColorByName(props.hoverColor)};

      path,
      text,
      polygon
      {
        fill: ${props => getColorByName(props.hoverColor)};
        stroke: ${props => getColorByName(props.hoverColor)};
      }
    }
  }
`

export const AnchorButtonMain = styled.a.attrs(props => ({
  padding: props.padding || `1em 0.5em`,
  margin: props.margin || `0`,
  fontSize: props.fontSize || FontSize.button.large,
  maxWidth: props.maxWidth || Widths.maxBtn,
  className: `Button ${props.className || ''}`,
  icon: props.icon || null,
  width: props.width || '100%',
  minWidth: props.minWidth || 'unset'
}))`
  position: relative;
  display: inline-block;
  font-family: ${Font.primary};
  font-size: ${props => props.fontSize};
  font-weight: ${FontWeight.regular};
  text-align: center;
  box-sizing: border-box;
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  border: 1px solid;
  cursor: pointer;
  white-space: normal;
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  min-width: ${props => props.minWidth};
  text-decoration: none;
  line-height: normal;
  transition: 0.2s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  @media screen and (min-width: ${Widths.minTablet}) {
    ${props => props.icon && !(props.icon.includes('.svg') || props.icon.includes('image/svg'))
      ? `position: relative;
         padding-left: calc(1em + 25px);
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0.9em;
            transform: translateY(-50%);
            width: 25px;
            height: 25px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(${props.icon});
          }
        &:hover {
          &:before {
            -webkit-filter: invert(100%);
          }
        }`
      : ``}
  }
`

export const AnchorButton = styled(AnchorButtonMain).attrs(props => ({
  color: props.color || `white`,
  bgColor: props.bgColor || `red`,
  borderColor: props.borderColor || `red`,
  hoverColor: props.hoverColor || `red`,
  hoverBgColor: props.hoverBgColor || `white`,
  hoverBorderColor: props.hoverBorderColor || `red`,
}))`
  color: ${props => getColorByName(props.color)};
  background-color: ${props => getColorByName(props.bgColor)};
  border-color: ${props => getColorByName(props.borderColor)};

  .SVGContainer {
    ${btnSvgStyles}

    svg {
      fill: ${props => getColorByName(props.color)};
      stroke: ${props => getColorByName(props.color)};

      path,
      text,
      polygon
      {
        fill: ${props => getColorByName(props.color)};
        stroke: ${props => getColorByName(props.color)};
      }
    }
  }

  &:hover {
    color: ${props => getColorByName(props.hoverColor)};
    background-color: ${props => getColorByName(props.hoverBgColor)};
    border-color: ${props => getColorByName(props.hoverBorderColor)};

    svg {
      fill: ${props => getColorByName(props.hoverColor)};
      stroke: ${props => getColorByName(props.hoverColor)};

      path,
      text,
      polygon
      {
        fill: ${props => getColorByName(props.hoverColor)};
        stroke: ${props => getColorByName(props.hoverColor)};
      }
    }
  }
`

export const AnchorButtonAlt = styled(AnchorButtonMain).attrs(props => ({
  color: props.color || `black`,
  bgColor: props.bgColor || `transparent`,
  borderColor: props.borderColor || `black`,
  hoverColor: props.hoverColor || `white`,
  hoverBgColor: props.hoverBgColor || `black`,
  hoverBorderColor: props.hoverBorderColor || `black`,
}))`
  color: ${props => getColorByName(props.color)};
  background-color: ${props => getColorByName(props.bgColor)};
  border-color: ${props => getColorByName(props.borderColor)};

  .SVGContainer {
    ${btnSvgStyles}

    svg {
      fill: ${props => getColorByName(props.color)};
      stroke: ${props => getColorByName(props.color)};

      path,
      text,
      polygon
      {
        fill: ${props => getColorByName(props.color)};
        stroke: ${props => getColorByName(props.color)};
      }
    }
  }

  &:hover {
    color: ${props => getColorByName(props.hoverColor)};
    background-color: ${props => getColorByName(props.hoverBgColor)};
    border-color: ${props => getColorByName(props.hoverBorderColor)};

    svg {
      fill: ${props => getColorByName(props.hoverColor)};
      stroke: ${props => getColorByName(props.hoverColor)};

      path,
      text,
      polygon
      {
        fill: ${props => getColorByName(props.hoverColor)};
        stroke: ${props => getColorByName(props.hoverColor)};
      }
    }
  }
`

export const HoverCircle = ({
  active,
  text,
  padding = `25%`,
  currDevice
}) => {
  return (
    <HoverCircleBackground
      currDevice={currDevice}
      active={active}
      text={text}
      padding={padding}
    >
      <HoverCircleText currDevice={currDevice} >{text}</HoverCircleText>
    </HoverCircleBackground>
  )
}

export const HoverCircleBackground = styled(Block).attrs(props => ({
  active: props.active || false,
  padding: props.padding || `6em`,
  className: `HoverCircleBackground`,
  currDevice: props.currDevice || `desktop`,
}))`
  content: "";
  position: absolute;
  cursor: pointer;
  top: calc((100% - ${props => props.padding} - ${props => props.padding}) / 2);
  left: calc((100% - ${props => props.padding} - ${props => props.padding}) / 2);
  width: 0;
  height: 0;
  line-height: 0;
  background: ${Color.blue60pc};
  border-radius: 50%;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  opacity: ${props => props.active
    ? `1;`
    : `0;`}
  padding: ${props => props.padding};
  z-index: 5;
  // animations on active state
  ${props => props.active && `animation: hover 1200ms linear 2 alternate;`}
    // alternatively, on hover
    // &:hover {
    //   animation: hover 1200ms linear 2 alternate;
    // }
`;

export const HoverCircleText = styled(Span).attrs(props => ({
  colorName : `white`,
  className: `HoverCircleText`,
  currDevice: props.currDevice || `desktop`,
}))`
  font-weight: ${FontWeight.bold};
  font-size: ${props => 'mobile' === props.currDevice
    ? FontSize.hoverCircle.mobile
    : 'tablet' === props.currDevice
      ? FontSize.hoverCircle.tablet
      : FontSize.hoverCircle.desktop
  };
  line-height: 1.2em;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const ZoomIcon = styled(Block).attrs(props => ({
  active: props.active || false,
  color: `${props.color || Color.white}`,
  className: `ZoomIcon ${props.className || ''}`
}))`
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: ${props => props.color};
  font-size: 1.2em;
  opacity: ${props => props.active
    ? `1;`
    : `0;`}
`;

export const HorizontalLine = styled(Block).attrs(props => ({
  width: props.width || `100%`,
  height: props.height || `1px`,
  color: props.color || `lightGray`,
  margin: props.margin || `38px 0`,
  className: `HorizontalLine ${props.className || ''}`
}))`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  background-color: ${props => getColorByName(props.color)};
`;

export const UList = styled.ul.attrs(props => ({
  width: props.width || `100%`,
  color: props.color || `lightGray`,
  margin: props.margin || `1em auto 1em 1em`,
  className: `UnorderedList ${props.className || ''}`
}))`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
`;

export const Rollover = styled(Block).attrs(props => ({
  className: `Rollover ${props.className || ''}`
}))`
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  opacity: 0;
  color: ${Color.white};
  background: ${Color.black};
  font-size: ${FontSize.rollover};
  padding: 0.4em;
  min-width: 90px;
  text-align: center;
  border-radius: 3px;
  transition: 0.2s;
`;
