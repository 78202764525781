/*
  Grids
 */
import styled from 'styled-components'
import {Block} from './elements'


export const GridContainer = styled(Block).attrs(props => ({
  rows: props.rows || 'auto',
  columns: props.columns || 'auto',
  gap: props.gap || '0 0',
  justify: props.justify || 'stretch',
  alignItems: props.alignItems || 'stretch',
  padding: props.padding || '0',
  bgColor: props.bgColor || 'transparent',
}))`
  width: 100%;
  display: ${props => props.inline && 'inline-'}grid;
  grid-template-rows: ${props => props.rows};
  grid-template-columns: ${props => props.columns};
  grid-gap: ${props => props.gap};
  justify-content: ${props => props.justify};
  align-items: ${props => props.alignItems};
  box-sizing: border-box;
  padding: ${props => props.padding};
  background-color: ${props => props.bgColor};
  max-height: ${props => props.maxHeight || '100%'};
  overflow: ${props => props.overflow || 'hidden'};
`;


export const GridItem = styled(Block)`
  text-align: ${props => props.alignContents
    ? props.alignContents
    : `left`};
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;
