/*
  create global styles like columns and rows, or other elements used through out the whole project
 */

export const Widths = {
  minDesktop: '1281px',
  maxLaptop: '1280px',
  minLaptop: '1024px',
  maxTablet: '1023px',
  minTablet: '768px',
  maxMobile: '767px',
  restrictedContent: '1198px',
  maxBtn: '195px',
  subMenu: {
    oneColMin: {
      mobile: '280px',
      desktop: '280px',
    },
    twoColMin: {
      mobile: '550px',
      desktop: '550px',
    },
  },
  logo: {
    small: '145px',
    medium: '160px',
    large: '272px',
  },
  icons: {
    xxsmall: '15px',
    xsmall: '20px',
    small: '25px',
    medium: '30px',
    large: '35px',
    xlarge: '40px',
    xxlarge: '45px',
  }
}

export const Font = {
  primary: 'Assistant, sans-serif',
  secondary: 'Fjalla One, sans-serif',
  tertiary: 'Open Sans, sans-serif',
}

export const FontSize = {
  body: '1.6em',
  header: {
    menuItem: {
      mobile: '1.8em',
      tablet: '1.6em',
      desktop: '1.6em',
    },
  },
  heading: {
    h1: {
      mobile: '3.5em',
      tablet: '4.5em',
      desktop: '6em',
    },
    h2: {
      mobile: '2.2em',
      tablet: '3em',
      desktop: '3.5em',
    },
    h3: {
      mobile: '2.2em',
      tablet: '2.2em',
      desktop: '2.2em',
    },
    h4: {
      mobile: '1.75em',
      tablet: '1.75em',
      desktop: '1.75em',
    },
    h5: {
      mobile: '1.8em',
      tablet: '1.8em',
      desktop: '1.8em',
    },
    h6: {
      mobile: '1.6em',
      tablet: '1.6em',
      desktop: '1.6em',
    },
  },
  button: {
    small: '1.4em',
    large: '1.8em',
  },
  hoverCircle: {
    mobile: '1.8em',
    tablet: '2em',
    desktop: '2.2em',
  },
  rollover: '14px',
  footer: {
    menuHeading: '1.6em',
    menuItem: {
      mobile: '1.4em',
      tablet: '1.6em',
      desktop: '1.6em',
    },
    copyright: {
      mobile: '1em',
      tablet: '1.2em',
      desktop: '1.4em',
    },
  },
}

export const FontWeight = {
  regular: '400',
  semiBold: '600',
  bold: '800',
}

export const LineHeight = {
  regular: '1em',
  one: '1.375em',
  two: '1.5em',
  three: '1.9em',
}

export const LetterSpacing = {
  small: '0.7px',
  regular: '1px',
}

export const Color = {
  blue: '#5881bc',
  blue60pc: 'rgba(88,129,188,0.6)',
  lightBlue: '#a3c0ea',
  veryLightBlue: '#99ccff',
  red: '#da614f',
  gray: '#a6a8ab',
  lightGray: '#f6f6f6',
  slateGray: '#e9e9e9',
  mediumGray: '#999999',
  darkGray: '#a1a1a1',
  black: '#333333',
  black20pc: 'rgba(0,0,0,0.2)',
  black40pc: 'rgba(0,0,0,0.4)',
  green: '#9bc8b1',
  white: 'white',
  offWhite: 'ghostwhite',
  transparent: 'transparent',
  lavendder: '#aeabd0',
  tangerine: '#ebb475',
  error: '#da614f',
  notice: '#5881bc',
}

export const Padding = {
  header: {
    mobile: '',
    tablet: '',
    desktop: '12px',
  },
  moduleSection: '12vw 6vw',
  slimModuleSection: '6vw 6vw',
  ultraSlimModuleSection: '4.5vw 6vw',
  padding0: '1.5vw',
  padding1: '3vw',
  padding1_5: '4.5vw',
  padding2: '6vw',
  padding3: '60px',
  padding4: '12vw',
  padding5: '50px',
}

export const Margin = {
  paragraph: '0.5em auto 0.5em 0',
  productDescription: '0 auto 1.5em 0',
}

export const BoxShadow = {
  small: '0 2px 5px -2px rgba(0,0,0,0.3)',
  medium: '0 2px 10px -2px rgba(0,0,0,0.3)',
  large: '0 2px 15px -2px rgba(0,0,0,0.3)',
}

export const restrictedWidth = `
  max-width: ${Widths.restrictedContent};
  margin-left: auto;
  margin-right: auto;
`
