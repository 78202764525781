import styled from "styled-components";
import { Color, Widths } from "../../../../styles";

export const Wrapper = styled.div`
  max-width: 1198px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.5rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;

  @media screen and (min-width: ${Widths.minTablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${Widths.minLaptop}) {
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 6rem;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    margin-bottom: 0;
  }
`;

export const MainHeading = styled.div`
  text-align: center;

  @media screen and (min-width: ${Widths.minLaptop}) {
    margin-bottom: 5rem;

    h2 {
      font-size: 3.5em;
      line-height: 28px;
    }
  }
`;

export const Overline = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  font-size: 1rem;
  color: ${Color.blue};
  margin-bottom: 0.75rem;
`;

export const WrapImage = styled.div`
  margin-bottom: 1.5rem;
`;

export const Excerpt = styled.p`
  color: ${Color.black};
  font-size: 2.2em;
`;

export const Link = styled.a`
  color: ${Color.red};
  text-decoration: none;
  font-weight: bold;
  font-size: 1.6em;
  display: inline-flex;
  gap: 0.25rem;
`;

export const Content = styled.div`
  margin-bottom: 1rem;
`;
