import styled from "styled-components";
import { Color, Font, Widths } from "../../../../styles";

export const Wrapper = styled.div`
  max-width: 1198px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.75rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${Widths.minTablet}) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 4rem;
    gap: 4rem;
  }
`;

export const WrapCopy = styled.div`
  padding: 0 1.5rem 2rem 1.5rem;

  h6 {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: ${Widths.minLaptop}) {
    padding: 0 2rem 0 0;
  }
`;

export const Copy = styled.div.attrs((props) => ({
  collapsed: props.collapsed,
}))`
  max-height: ${(props) => (props.collapsed ? "600px" : "6rem")};
  overflow: hidden;
  transition: max-height 0.25s ease-out;
  margin-bottom: 1rem;

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0;
  font-family: ${Font.primary};
  color: ${Color.red};
  display: inline-flex;
  gap: 0.25rem;
`;
