import React, {useState, useEffect} from "react";
import { useParams } from 'react-router-dom'
import TemplateFactory from "../Templates/TemplateFactory";
import PageAPIClient from '../../api/clients/page'
import Loading from '../Components/Loading'
import { arrEmpty } from '../Helpers'

export default function CustomPage({slug}) {
  // router
  const router = useParams()
  const pageSlug = (router && router.slug) || slug
  // state
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(null)
  const [titleArea, setTitleArea] = useState(null)
  let allowedToLoad = true;

  useEffect(() => {
    // check if a hero module is in-use
    const hasHero = (sektions) => {
      let heroFound = false
      if (!arrEmpty(sektions.length)) {
        sektions.forEach(sektion => { if ('hero' === sektion.section_name) heroFound = true })
      }
      return heroFound;
    }

    const load = async () => {
      setIsLoading(true)
      try {
        const API = new PageAPIClient()
        const response = await API.getPageBySlug(pageSlug || 'home')
        if (true === response.success && response.page) {
          const page = response.page
          // set page state
          setPage(page);
          const _sections = page.sections;
          // set title area state (if no 'hero' module exists...)
          /*eslint no-unused-expressions: ["error", { "allowShortCircuit": true }]*/
          if (!hasHero(_sections)) {
            setTitleArea({
              title: page.title || null,
              image: page.featured_image?.large
                ? page.featured_image?.large[0]
                : null,
              breadcrumbs: [
                {
                  'title': 'Primary Category',
                  'url': '#',
                },
                {
                  'title': 'Secondary Category',
                  'url': '#',
                },
              ]
            })
          }
        } else {
          setTitleArea({
            title: 'Oops..',
            breadcrumbs: [{
              title: 'Go Back Home',
              url: '/'
            }]
          })
          setPage({content: response.message})
        }
      } catch(error) {
        console.error(error)
      }
      setIsLoading(false)
    }

    (router
      && '_error' !== router.pathname)
      && allowedToLoad
      && null !== pageSlug // required, as the pageSlug state changes from null to the actual pageSlug
      && load()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => allowedToLoad = false;
  }, [pageSlug])

  if (!isLoading && !page) return null

  return (isLoading && !page
    ? <Loading inclIcon={true} />
    : <TemplateFactory
      title={titleArea}
      content={page.content}
      template={page.template}
      modules={page.sections}
      sidebarModules={page.sidebar}
      footerModules={page.footer}
    />)
}
