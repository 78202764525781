import React from "react";
import {
  Copy,
  Features,
  Grid,
  GridTitle,
  Headline,
  Item,
  WrapImage,
  Wrapper,
} from "./styles";
import { Heading } from "../../../../styles";

export default function FeatureHighlights({ heading, features }) {
  return (
    <Wrapper>
      <Grid>
        {heading && (
          <GridTitle>
            <Heading fontWeight={700} color="white">
              {heading}
            </Heading>
          </GridTitle>
        )}

        {features.length > 0 && (
          <Features>
            {features.map((item, index) => (
              <Item key={index}>
                <WrapImage>
                  <img src={item.icon.url} alt={item.icon.alt} />
                </WrapImage>
                <div>
                  <Headline>{item.headline}</Headline>
                  <Copy>{item.copy}</Copy>
                </div>
              </Item>
            ))}
          </Features>
        )}
      </Grid>
    </Wrapper>
  );
}
