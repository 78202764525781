import React from "react";
import { Copy, Grid, Wrapper } from "./styles";

export default function ImagesList({ copy, images }) {
  return (
    <Wrapper>
      {copy && <Copy dangerouslySetInnerHTML={{ __html: copy }} />}
      {images && (
        <Grid>
          {images.map((item, index) => (
            <img key={index} src={item.picture.url} alt={item.picture.alt} />
          ))}
        </Grid>
      )}
    </Wrapper>
  );
}
