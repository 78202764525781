import React from "react";
import {
  Container,
  Grid,
  Headline,
  MainHeading,
  Service,
  WrapImage,
  Wrapper,
} from "./styles";
import { Heading } from "../../../../styles";

export default function ServicesSummary({ heading, services }) {
  return (
    <Wrapper>
      <Container>
        {heading && (
          <MainHeading>
            <Heading type="h2" color="white">
              {heading}
            </Heading>
          </MainHeading>
        )}
        {services.length && (
          <Grid>
            {services.map((item, index) => (
              <Service key={index}>
                <WrapImage>
                  <img src={item.icon.url} alt={item.icon.alt} />
                </WrapImage>
                <Headline>{item.headline}</Headline>
              </Service>
            ))}
          </Grid>
        )}
      </Container>
    </Wrapper>
  );
}
