import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FavoritesButton from "../../../Components/Favorites/FavoritesButton";
import {
  arrEmpty,
  createMarkup,
  getThumbnail,
  useWindowSize,
} from "../../../Helpers";
import {
  FontSize,
  GridItemAnchor,
  AnimatedSlideItem,
  ProductImage,
  ProductTitle,
  ProductContentContainer,
  ProductDescription,
  ButtonContainer,
  AnchorButtonAlt,
} from "../../../../styles";

import { useSpectraAnalytics } from "spectra/src/hooks/useSpectraAnalytics";

export default function ProductSlideItemContainer({
  product,
  showDescription = true,
  showButtons = true,
}) {
  // router
  const router = useHistory();
  // states
  const [isActive, setIsActive] = useState(false);
  const [routePrimed, setRoutePrimed] = useState(false);
  const [primarySpecSheet, setPrimarySpecSheet] = useState(null);
  // vars
  const windowSize = useWindowSize();
  const prodFiles = product?.sections?.product_files?.files || null;
  const prodImage = getThumbnail(product);

  const analytics = useSpectraAnalytics();

  useEffect(() => {
    let primaryFile = null;
    if (!arrEmpty(prodFiles)) {
      primaryFile = prodFiles.find((file) => !!file.primary_spec_sheet);
    }
    setPrimarySpecSheet(primaryFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const handleProductRoute = () =>
    (
      analytics &&
      analytics.logEvent("product_slider_click", { product: product.name })
    )(
      "mobile" !== windowSize.currDevice && "tablet" !== windowSize.currDevice
        ? router.push(`/product/${product.name}`)
        : routePrimed
        ? router.push(`/product/${product.name}`)
        : setRoutePrimed(true)
    );

  const handleActivate = () => {
    setIsActive(true);
  };

  const handleDeactivate = () => {
    setIsActive(false);
    setRoutePrimed(false);
  };

  if (!product) return null;

  return (
    <AnimatedSlideItem
      active={isActive}
      onMouseEnter={() => handleActivate()} // req. for mobile
      onMouseOver={() => handleActivate()} // desktop edge-cases
      onMouseLeave={() => handleDeactivate()}
    >
      {prodImage && (
        <GridItemAnchor onClick={() => handleProductRoute()}>
          <ProductImage
            hovered={isActive}
            backgroundImage={prodImage}
          ></ProductImage>
        </GridItemAnchor>
      )}
      <ProductContentContainer onClick={() => handleProductRoute()}>
        {product.title && (
          <ProductTitle
            type={"h5"}
            fontWeight={"800"}
            children={product.title}
          />
        )}
        {!!showDescription &&
          product.sections?.product_details?.short_description && (
            <ProductDescription
              dangerouslySetInnerHTML={createMarkup(
                product.sections?.product_details.short_description
              )}
            />
          )}
      </ProductContentContainer>
      {!!showButtons && (
        <ButtonContainer active={isActive}>
          <FavoritesButton
            product={product}
            fontSize={FontSize.button.small}
            padding={"0.75em 0.2em"}
            inclIcon={false}
          />
          {primarySpecSheet && (
            <AnchorButtonAlt
              fontSize={FontSize.button.small}
              padding={"0.75em 0.2em"}
              href={primarySpecSheet?.file?.url}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              {"View Spec Sheet"}
            </AnchorButtonAlt>
          )}
        </ButtonContainer>
      )}
    </AnimatedSlideItem>
  );
}
