import React, { useState } from "react";
import { Container, Dot, Dots, QuoteText, Wrapper, WrapQuote } from "./styles";
import { Heading } from "../../../../styles";

export default function QuoteSlider({ title, quotes }) {
  const [currentQuote, setCurrentQuote] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleDotClick = (index) => {
    setIsFadingOut(true);
    setTimeout(() => {
      setCurrentQuote(index);
      setIsFadingOut(false);
    }, 250);
  };

  return (
    <Wrapper>
      <Container>
        <Heading type="h3" color="blue">
          {title}
        </Heading>
        {quotes.length > 0 && (
          <WrapQuote>
            <QuoteText
              className={isFadingOut ? "fade-out" : "fade-in"}
              key={currentQuote}
            >
              <div
                dangerouslySetInnerHTML={{ __html: quotes[currentQuote].copy }}
              />
            </QuoteText>
            <Dots>
              {quotes.map((_, index) => (
                <Dot
                  key={index}
                  className={currentQuote === index ? "active" : ""}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </Dots>
          </WrapQuote>
        )}
      </Container>
    </Wrapper>
  );
}
