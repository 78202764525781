import { Button, Copy, WrapCopy, Wrapper } from "./styles";
import { Heading } from "../../../../styles";
import React, { useState } from "react";

export default function CollapsedContent({ title, copy, image }) {
  const [open, setOpen] = useState(false);
  return (
    <Wrapper>
      <div>{image && <img src={image.url} alt={image.alt} width="100%" />}</div>
      <WrapCopy>
        {title && (
          <Heading color="black" type="h6" fontWeight={700}>
            {title}
          </Heading>
        )}
        {copy && (
          <Copy
            collapsed={open}
            dangerouslySetInnerHTML={{
              __html: copy,
            }}
          />
        )}
        <Button onClick={() => setOpen(!open)}>
          <span>{open ? "Close" : "More"}</span>
          {!open && <span>&#x25BE;</span>}
        </Button>
      </WrapCopy>
    </Wrapper>
  );
}
