import styled from "styled-components";
import { Color } from "../../../../styles";

export const Wrapper = styled.div.attrs((props) => ({
  spacing: props.margin || "small",
}))`
  margin-top: ${(props) =>
    props.spacing === "small"
      ? "1rem"
      : props.spacing === "medium"
      ? "2rem"
      : "4rem"};
  margin-bottom: ${(props) =>
    props.spacing === "small"
      ? "1rem"
      : props.spacing === "medium"
      ? "2rem"
      : "4rem"};

  hr {
    border: none;
    border-top: 1px solid ${Color.gray};
  }
`;
