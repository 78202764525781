import styled from "styled-components";
import { Widths } from "../../../../styles";

export const Wrapper = styled.div`
  max-width: 1198px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.75rem;
  margin-bottom: 2rem;

  @media screen and (min-width: ${Widths.minTablet}) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    margin-bottom: 4rem;
  }
`;

export const WrapCopy = styled.div.attrs((props) => ({
  orientation: props.orientation || "left",
}))`
  padding: 0 1.5rem 2rem 1.5rem;
  order: ${(props) => (props.orientation === "right" ? "-1" : "1")};

  h2 {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${Widths.minLaptop}) {
    padding: 0;
  }
`;

export const Copy = styled.div`
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  ul, ol {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-left: 10px;
  }
  li {
    margin-bottom: 8px;
  }
`;
