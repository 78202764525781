import React from "react";
import {
  TitleAreaContainer
} from './styles'
import {
  Heading
} from '../../../styles'

export default function TitleArea({
  title,
  tTransform
}) {
  return (
    title
      && <TitleAreaContainer
        backgroundImage={title.image}
      >
        <Heading
          type={'h1'}
          children={title.title}
          tTransform={tTransform}
          fontSize="9em"
        />
        {/* {outputBreadcrumbs(title.breadcrumbs)} */}
      </TitleAreaContainer>
  );
}
