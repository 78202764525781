/* 'dev' | 'devAlt' | 'staging' | 'production' */
const ENV = 'production'

let protocol = ''
let domain = ''

switch (ENV) {
  case 'dev':
    protocol = 'http'
    domain = 'api.quam.local'
    break;
  
  case 'devAlt':
    protocol = 'http'
    domain = 'localhost:9082'
    break;
  
  case 'staging':
    protocol = 'https'
    domain = 'api-quam-staging.photon.software'
    break;
  
  case 'production':
    protocol = 'https'
    domain = 'api.quamspeakers.com'
    break;
    
  default:
    protocol = 'https'
    domain = ''
}

const host = `${protocol}://${domain}`

const baseURL = `${host}/wp-json`

const quamURL = `${baseURL}/quam/v1`

const wpURL = `${baseURL}/wp/v2`

if ('production' !== ENV) {
  console.log('Environment: ', ENV)
}

/**
 * API Client class
 */

function getAuthorizedGetRequestHeaders (base64Auth) {
  return {
    Authorization: `Basic ${base64Auth}`
  }
}

function getPostRequestHeaders (base64Auth) {
  return {
    Authorization: `Basic ${base64Auth}`,
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
    // 'Content-Type': 'application/json',
    // 'X-Content-Type-Options': 'nosniff'
  }
}

function getPutRequestHeaders (base64Auth) {
  return {
    Authorization: `Basic ${base64Auth}`,
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data'
  }
}

function getDeleteRequestHeaders (base64Auth) {
  return {
    Authorization: `Basic ${base64Auth}`
  }
}

function prepareMediaForPost (mediaObj, key) {
  let _body = new FormData()
  _body.append(key, mediaObj)
  return _body
}

function optionsToURLParams (options) {
  let _params = ''
  for (var i in options) {
    if (options.hasOwnProperty(i)) {
      // check for empty Arrays
      if (Array.isArray(options[i])
        && 0 === options[i].length) {
        continue;
      }
      _params += i + '=' + encodeURIComponent(options[i]) + '&'
    }
  }
  return _params
}

function parseURLRequest ( url, options ) {
  let _url = url + '?'
  // build data from options
  _url += optionsToURLParams(options)
  return _url.substr(0, _url.length - 1)
}

function init() {
  // console.log('api initialized')
}

const api = {
  ENV,
  protocol,
  domain,
  host,
  baseURL,
  quamURL,
  wpURL,
  getAuthorizedGetRequestHeaders,
  getPostRequestHeaders,
  getPutRequestHeaders,
  getDeleteRequestHeaders,
  prepareMediaForPost,
  optionsToURLParams,
  parseURLRequest,
  init,
}

// api.init()

export default api
