import styled from "styled-components";
import { Widths } from "../../../../styles";

export const Wrapper = styled.div`
  padding: 3rem 2.5rem;
  margin-bottom: 1.5rem;

  @media screen and (min-width: ${Widths.minLaptop}) {
    padding: 3rem 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 3rem;
  justify-items: center;

  @media screen and (min-width: ${Widths.minTablet}) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  @media screen and (min-width: ${Widths.minLaptop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 14.5rem;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  gap: 2rem;
  align-items: flex-start;
`;

export const Copy = styled.div``;
