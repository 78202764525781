import React from "react";
import { Copy, WrapCopy, Wrapper } from "./styles";
import { Heading } from "../../../../styles";

export default function SplitContent({ title, copy, image }) {
  return (
    <Wrapper>
      <div>{image && <img src={image.url} alt={image.alt} width="100%" />}</div>
      <WrapCopy>
        {title && (
          <Heading color="blue" type="h2">
            {title}
          </Heading>
        )}
        {copy && (
          <Copy
            dangerouslySetInnerHTML={{
              __html: copy,
            }}
          />
        )}
      </WrapCopy>
    </Wrapper>
  );
}
