import styled from "styled-components";
import { Color, Widths } from "../../../../styles";

export const Wrapper = styled.div.attrs((props) => ({
  bgColor: props.bgColor || "white",
}))`
  background-color: ${(props) =>
    props.bgColor === "white" ? Color.white : Color.lightGray};
  padding: 2rem;
  border-radius: 18px;
  margin-bottom: ${(props) => (props.reduceMargins ? "0" : "1.5rem")};

  ul,
  ol {
    font-size: 1rem;
    line-height: 1.5;
    padding-left: 10px;
    list-style: disc;
  }
  li {
    margin-bottom: 8px;
  }

  p {
    font-size: 1.6em;
    margin-bottom: 1rem;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    color: ${Color.blue};
  }
  h1 {
    font-size: 3.5em;
  }
  h2 {
    font-size: 2.2em;
  }
  h3 {
    font-size: 2.2em;
  }
  h4 {
    font-size: 1.75em;
  }
  h5 {
    font-size: 1.75em;
  }
  h6 {
    font-size: 1.75em;
  }

  @media screen and (min-width: ${Widths.minLaptop}) {
    padding: ${(props) =>
      props.bgColor === "white"
        ? props.reduceMargins
          ? "0"
          : "2rem 0"
        : "3rem 3rem 2rem 3rem"};
  }
`;
