import React from "react";
import { useHistory } from "react-router-dom";
import { Parallax } from "react-parallax";
import VideoBackground from "../../../Components/VideoBackground";
import { useWindowSize, getLinkUrl } from "../../../Helpers";
import { Hero, Heading, Cta, Copy, WrapLinks } from "./styles";

const colorOptions = {
  transparent1: {
    color: "white",
    bgColor: "transparent",
    borderColor: "white",
    hoverColor: "black",
    hoverBgColor: "transparent",
    hoverBorderColor: "black",
  },
  transparent2: {
    color: "black",
    bgColor: "transparent",
    borderColor: "black",
    hoverColor: "white",
    hoverBgColor: "transparent",
    hoverBorderColor: "white",
  },
  black: {
    color: "white",
    bgColor: "black",
    borderColor: "black",
    hoverColor: "black",
    hoverBgColor: "white",
    hoverBorderColor: "white",
  },
  white: {
    color: "black",
    bgColor: "white",
    borderColor: "white",
    hoverColor: "white",
    hoverBgColor: "black",
    hoverBorderColor: "black",
  },
  blue: {
    color: "white",
    bgColor: "blue",
    borderColor: "blue",
    hoverColor: "white",
    hoverBgColor: "blue",
    hoverBorderColor: "blue",
  },
  red: {
    color: "white",
    bgColor: "red",
    borderColor: "red",
    hoverColor: "white",
    hoverBgColor: "red",
    hoverBorderColor: "red",
  },
};

export default function PageHero({
  mediaType,
  image,
  video,
  heading,
  headingColor,
  copy,
  copyColor,
  cta,
  ctaColor,
  ctaAlt,
  ctaColorAlt,
}) {
  // router
  const router = useHistory();

  // vars
  const windowSize = useWindowSize();
  const imageUrl = image?.sizes?.large;
  // load the right video url / sized image
  const mediaURL = "image" === mediaType ? imageUrl || "" : video?.url || "";
  // video thumbnail
  const posterURL =
    "video" === mediaType && video?.sizes?.large ? video.sizes.large : "";
  // CTA urls

  const renderCTA = (_cta, _ctaColor) => {
    if (_cta) {
      const newAtts = getLinkUrl((_cta && _cta.url) || "");
      return (
        <Cta
          onClick={() =>
            newAtts.external
              ? (window.location = newAtts.href)
              : router.push(newAtts.as)
          }
          maxWidth={`none`}
          {...colorOptions[_ctaColor || "transparent1"]}
        >
          {_cta.title}
        </Cta>
      );
    }
  };

  const renderHeroContent = () => (
    <>
      {"video" === mediaType && "mobile" !== windowSize.currDevice && (
        <VideoBackground
          url={mediaURL}
          poster={posterURL}
          autoplay={true}
          mute={true}
          background={true}
          loop={true}
          playsInline
        />
      )}

      {heading && (
        <Heading
          currDevice={windowSize.currDevice}
          headingColor={headingColor || "white"}
        >
          {heading}
        </Heading>
      )}
      {copy && (
        <Copy currDevice={windowSize.currDevice} copyColor={copyColor || "white"}>
          {copy}
        </Copy>
      )}

      <WrapLinks>
        {renderCTA(cta, ctaColor)}
        {renderCTA(ctaAlt, ctaColorAlt)}
      </WrapLinks>
    </>
  );

  return (
    <Parallax
      strength={200}
      bgImage={imageUrl}
      bgImageAlt="Quam Speakers Home Page Hero"
      bgClassName="object-cover"
    >
      <Hero>{renderHeroContent()}</Hero>
    </Parallax>
  );
}
