import styled from "styled-components";

import {
  Color,
  Padding,
  Block,
} from '../../../../styles'

export const Container = styled(Block).attrs(props => ({
  bgColor: props.bgColor || 'white',
  slimline: props.slimline || false,
  className: 'ProductRelatedProductsSlider'
}))`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  padding: ${props => props.slimline
    ? `${Padding.padding2} ${Padding.padding2} 0;`
    : `40px ${Padding.padding2};`}
  background: ${Color.lightGray};
  text-align: center;

  h2 {
    margin-top: 0;
    margin-bottom: ${props => 'mobile' === props.currDevice 
      ? `0.2em`
      : `2rem`};
  }
`;
