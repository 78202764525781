import styled from "styled-components";

import { Block, Font, AnchorButtonAlt, Widths } from "../../../../styles";

import { getColorByName } from "../../../../styles/helpers";

export const Hero = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-top: 10vw;
  padding-bottom: 10vw;
  min-height: 320px;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;

  @media screen and (min-width: ${Widths.minTablet}) {
    width: 62rem;
    margin: 0 auto;
  }
`;

export const Heading = styled.h1.attrs((props) => ({
  currDevice: props.currDevice || "desktop",
  headingColor: props.headingColor || `white`,
}))`
  position: relative;
  z-index: 3;
  color: ${(props) => getColorByName(props.headingColor)};
  font-family: ${Font.secondary};
  width: 100%;
  text-align: center;
  font-size: ${(props) =>
    "mobile" === props.currDevice
      ? `4.8em`
      : "tablet" === props.currDevice
      ? `6.8em`
      : `9em`};
  margin-bottom: 0.3em;
`;

export const Copy = styled.p.attrs((props) => ({
  currDevice: props.currDevice || "desktop",
  copyColor: props.copyColor || `white`,
}))`
  position: relative;
  z-index: 3;
  color: ${(props) => getColorByName(props.copyColor)};
  font-family: ${Font.primary};
  width: 100%;
  text-align: center;
  font-size: ${(props) =>
    "mobile" === props.currDevice
      ? `1.6em`
      : "tablet" === props.currDevice
      ? `1.8em`
      : `2.4em`};
  line-height: ${(props) =>
    "mobile" === props.currDevice ? `1.5rem` : "1.875rem"};
  margin: ${(props) => ("mobile" === props.currDevice ? `1rem auto` : `0 auto 3rem auto`)};
  max-width: 50rem;
`;

export const Cta = styled(AnchorButtonAlt).attrs((props) => ({
  currDevice: props.currDevice || "desktop",
}))`
  white-space: nowrap;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const WrapLinks = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${Widths.minTablet}) {
    display: inline-flex;
    flex-direction: row;
  }
`;
