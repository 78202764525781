import React from "react";
import {
  Content,
  Grid,
  Item,
  Link,
  MainHeading,
  Overline,
  WrapImage,
  Wrapper,
} from "./styles";
import { Heading } from "../../../../styles";

export default function LatestPosts({ title, posts }) {
  return (
    <Wrapper>
      <MainHeading>
        <Heading type="h2" color="gray">
          {title}
        </Heading>
      </MainHeading>
      {posts.length && (
        <Grid>
          {posts.map((item) => (
            <Item key={item.id}>
              <Content>
                {item.thumbnail && (
                  <WrapImage>
                    <img src={item.thumbnail} alt={item.title} />
                  </WrapImage>
                )}
                <Overline>
                  <p>{item.categories.join(", ")}</p>|{" "}
                  <p>{item.publish_date}</p>
                </Overline>
                <Heading>
                  {item.title
                    .split(" ")
                    .reduce((acc, word, index) => {
                      if (index < 10) {
                        acc.push(word);
                      }
                      return acc;
                    }, [])
                    .join(" ")}
                </Heading>
              </Content>

              <Link href={`post/${item.slug}`}>
                <span>MORE</span>
                <span>&#62;</span>
              </Link>
            </Item>
          ))}
        </Grid>
      )}
    </Wrapper>
  );
}
