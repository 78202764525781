import React from "react";
import { Copy, WrapCopy, Wrapper } from "./styles";
import { AnchorButton, Heading } from "../../../../styles";
import { getLinkUrl } from "../../../Helpers";
import { useHistory } from "react-router-dom";

export default function SideBySide({
  title,
  copy,
  image,
  cta,
  orientation = "left",
}) {
  const router = useHistory();
  const newAtts = getLinkUrl((cta && cta.url) || "");

  const renderCTA = () => {
    if (cta && newAtts) {
      return (
        <AnchorButton
          onClick={() =>
            newAtts.external
              ? (window.location = newAtts.href)
              : router.push(newAtts.as)
          }
          color={`black`}
          borderColor={`black`}
          bgColor={`transparent`}
          hoverColor={`white`}
          hoverBorderColor={`black`}
          hoverBgColor={`black`}
          maxWidth={`none`}
          width={`auto`}
          padding={`0.5em 1em`}
        >
          {cta.title}
        </AnchorButton>
      );
    }
  };
  return (
    <Wrapper>
      <div>{image && <img src={image.url} alt={image.alt} width="100%" />}</div>
      <WrapCopy orientation={orientation}>
        {title && (
          <Heading color="blue" type="h3">
            {title}
          </Heading>
        )}
        {copy && (
          <Copy
            dangerouslySetInnerHTML={{
              __html: copy,
            }}
          />
        )}

        {renderCTA()}
      </WrapCopy>
    </Wrapper>
  );
}
