import React from "react";
import { useWindowSize } from "../../Helpers";
import ModuleFactory from "../../Modules/ModuleFactory";
import SidebarModuleFactory from "../../SidebarModules/SidebarModuleFactory";
import FooterModuleFactory from "../../FooterModules/FooterModuleFactory";
import TitleArea from "../../Components/TitleArea";
import WYSIWYGContent from "../../Components/WYSIWYGContent";
import {
  Padding,
  ContentContainer,
  ContentWrapper,
  GridContainer,
  GridItem,
} from "../../../styles";

export default function MixedRightSidebarTemplate({
  title,
  content,
  modules,
  sidebarModules,
  footerModules,
}) {
  // vars
  const windowSize = useWindowSize();
  let gap = "2.5%";
  let columns = "75% 22.5%";
  let rows = "auto";
  if ("mobile" === windowSize.currDevice) {
    gap = "5em";
    columns = "repeat(1,100%)";
    rows = "repeat(2,auto)";
  } else if ("tablet" === windowSize.currDevice) {
    gap = "5em";
    columns = "repeat(1,100%)";
    rows = "repeat(2,auto)";
  }
  const hasHero = modules.some((module) => "hero" === module.section_name);

  return (
    <ContentContainer>
      {hasHero && (
        <ModuleFactory
          module={modules.find((module) => "hero" === module.section_name)}
        />
      )}

      {"object" === typeof title && !hasHero && <TitleArea title={title} tTransform="none" />}

      <ContentWrapper currDevice={windowSize.currDevice}>
        <GridContainer
          gap={gap}
          columns={columns}
          rows={rows}
          padding={`${Padding.padding2} 0`}
        >
          <GridItem>
            <WYSIWYGContent content={content} />
            {modules &&
              modules.map((module, i) => {
                if ("hero" === module.section_name) return null;
                return <ModuleFactory module={module} key={i} />;
              })}
          </GridItem>
          <GridItem>
            {sidebarModules &&
              sidebarModules.map((module, i) => (
                <SidebarModuleFactory key={i} module={module} />
              ))}
          </GridItem>
        </GridContainer>
      </ContentWrapper>
      {footerModules &&
        footerModules.map((module, i) => (
          <FooterModuleFactory key={i} module={module} />
        ))}
    </ContentContainer>
  );
}
