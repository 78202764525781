import styled from "styled-components";
import { Color, Widths } from "../../../../styles";

export const Wrapper = styled.div``;

export const Copy = styled.div`
  margin-bottom: 1.5rem;

  ul,
  ol {
    font-size: 1rem;
    line-height: 1.5;
  }

  p {
    font-size: 1.6em;
    margin-bottom: 1rem;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    color: ${Color.blue};
  }
  h1 {
    font-size: 3.5em;
  }
  h2 {
    font-size: 2.2em;
  }
  h3 {
    font-size: 2.2em;
  }
  h4 {
    font-size: 1.75em;
  }
  h5 {
    font-size: 1.75em;
  }
  h6 {
    font-size: 1.75em;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  justify-items: center;

  @media screen and (min-width: ${Widths.minLaptop}) {
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
  }
`;
