import React, { useState, useRef } from "react";
// import ConstantContact from '../../../Components/ConstantContact'
import ContactFormAPIClient from '../../../../api/clients/contactForm'
import { formConfig } from './Form.config'
import { Container } from "./styles";
import {
  GridContainer,
  GridItem,
  Heading,
  P,
  FormContainer,
  InputText,
  Button,
  MailSuccess,
  MailError,
} from '../../../../styles'
import { 
  useWindowSize,
  validateEmail,
  useLocation,
} from '../../../Helpers'

export default function SubscribeToNewsletter({
  headline,
  title,
  slimline
}) {
  // state
  const [mailSuccess, setMailSuccess] = useState(false)
  const [mailFailed, setMailFailed] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // refs
  const inputEmailEl = useRef(null)
  // location
  const location = useLocation()
  // vars
  const windowSize = useWindowSize()
  let gap = '0.5em'
  let columns = '48% 52%'
  let rows = 'auto'
  if ('mobile' === windowSize.currDevice) {
    gap = '0.5em'
    columns = 'repeat(1,auto)'
    rows = 'repeat(2,auto)'
  } else if ('tablet' === windowSize.currDevice) {
    gap = '3.5em'
    columns = 'repeat(1,auto)'
    rows = 'repeat(2,auto)'
  }

  const handleNewsletterSubscription = (e) => {
    e.preventDefault()
    e.stopPropagation()
    
    // disable btn
    setIsLoading(true)

    // validate email
    const inputEmailValid = validateEmail(inputEmailEl.current.value)    

    if (inputEmailValid) {
      submitForm({
        subject: 'New Subscriber',
        toAdmin: 'qnc@qnc.com',
        bodyAdmin: inputEmailEl.current.value,
        toClient: inputEmailEl.current.value,
        bodyClient: `Hi,<br><br>You've been subscribed to our Quam Speakers newsletter.`,
        url: location.href,
      })
    } else {
      setMailSuccess(false)
      setMailFailed(true)
      setIsLoading(false)
      setIsError(false)
    }
  }

  const submitForm = async (formData) => {
    // console.log('formData', formData)
    try {
      const API = new ContactFormAPIClient()
      const response = await API.submitContactForm(formData)
      // console.log('response', response)
      // handle response if successful
      if (true === response.success) {
        setMailSuccess(true)
        setMailFailed(false)
        setIsError(false)
        resetForm()
      } else {
        setMailSuccess(false)
        setMailFailed(false)
        setIsError(true)
      }
    } catch(err) {
      setMailSuccess(false)
      setMailFailed(false)
      setIsError(true)
      console.error(err)
    }
    setIsLoading(false)
  }

  const resetForm = () => {
    inputEmailEl.current.value = '';
  }

  return (
    <Container
      slimline={slimline}
    >
      <GridContainer
        gap={gap}
        columns={columns}
        rows={rows}
      >
        {
          <GridItem>
            <P
              size={'2.4em'}
              color={'white'}
            >
              {headline}
            </P>
            <Heading
              type={'h2'}
              children={title}
              color={'white'}
            />
          </GridItem>
        }
        <GridItem>
          {
            // <ConstantContact
            //   formId={'0177f636-f172-4da0-95fd-c4e033618a7e'}
            // />
          }
          {
            <FormContainer>
              <InputText
                ref={inputEmailEl}
                maxWidth={'68%'}
                margin={'0 2% 0 0'}
                type={'text'}
                placeholder={formConfig.formFields.email.placeholder}
                required={formConfig.formFields.email.required}
              />
              <Button
                maxWidth={'30%'}
                padding={'1em 0.5em'}
                bgColor={'green'}
                borderColor={'green'}
                hoverColor={'green'}
                hoverBgColor={'white'}
                hoverBorderColor={'green'}
                type={'submit'}
                onClick={(e) => handleNewsletterSubscription(e)}
                disabled={isLoading}
              >
                {'Subscribe'}
              </Button>
            </FormContainer>
          }
          {mailFailed
            && <MailError>
              {formConfig.mailFail}
            </MailError>
          }
          {isError
            && <MailError>
              {formConfig.formError}
            </MailError>
          }
          {mailSuccess
            && <MailSuccess>
              {formConfig.mailSent}
            </MailSuccess>
          }
        </GridItem>
      </GridContainer>
    </Container>
  );
}
