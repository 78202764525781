import styled from 'styled-components'

import {
  Color,
  FontSize,
  FontWeight,
  LineHeight,
  Widths,
  Padding,
  Heading,
  Block,
  P,
  coverBackground,
  Anchor,
} from './index'

export const hoverReduction = '30px'

export const ProductGridContainer = styled(Block).attrs(props => ({
  padding: props.padding || '2% 0',
  className: `ProductGridContainer ${props.className || ''}`
}))`
  width: 100%;
  position: relative;
`;

export const ProductGridWrapper = styled(Block).attrs(props => ({
  padding: props.padding || '2% 0 0',
  className: `ProductGridWrapper ${props.className || ''}`
}))`
  position: relative;
  text-align: center;
  width: 102%;
  margin: 0 -1%;
  box-sizing: border-box;
  overflow: visible;
  padding: ${props => props.padding};

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const GridItemAnchor = styled(Anchor)``

export const ProductGridItem = styled(Block).attrs(props => ({
  active: props.active || false,
  flexDesktop: props.flexDesktop || `0 0 23%`,
  flexTablet: props.flexTablet || `0 0 48%`,
  flexMobile: props.flexMobile || `0 0 98%`,
  padding: props.padding || `0`,
  margin: props.margin || `0`,
  className: `ProductGridItem ${props.className || ``}`
}))`
  // scroll-snap-align: start;
  // scroll-snap-stop: always;
  display: inline-block;
  height: 100%;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  box-sizing: border-box;
  flex: ${props => props.flexMobile};

  @media screen and (min-width: ${Widths.minLaptop}) {
    flex: ${props => props.flexDesktop};
  }
`;

export const AnimatedGridItem = styled(ProductGridItem)`
  position: relative;
  vertical-align: top;
  transition: 0.2s;
  box-shadow: ${props => props.active
    ? `0 0 10px 0 ${Color.black20pc};`
    : `0;`}
  margin-bottom: ${props => props.active
    ? `calc(${hoverReduction} + ${Padding.padding1} - 35px);`
    : `calc(${Padding.padding1} - 35px);`}
`;

export const ProductImage = styled(Block)`
  ${props => coverBackground(props.backgroundImage)}
  position: relative;
  height: 0;
  transition: 0.2s;
  padding-top: ${props => props.hovered
    ? `calc(100% - ${hoverReduction});`
    : `100%;`}
  background-color: ${Color.white};
`;

export const ProductContentContainer = styled(Block)`
  width: 95%;
  margin: 0 auto;
`;

export const ProductTitle = styled(Heading)`
    font-weight: ${FontWeight.bold};
    margin-top: 0.5em;
`;

export const ProductDescription = styled(P)`
  white-space: normal;
  color: ${Color.black};
  line-height: ${LineHeight.one};
  font-size: ${FontSize.body};

  @media screen and (min-width: ${Widths.minTablet}) {
    font-size: ${FontSize.body};
  }
  @media screen and (min-width: ${Widths.minDesktop}) {
    font-size: ${FontSize.body};
  }
`;

export const ProductModelCount = styled(P)`
  white-space: normal;
  color: ${Color.red};
  font-style: italic;
  font-size: 1.5em;
`;

export const ButtonContainer = styled(Block).attrs(props => ({
  active: props.active || false,
  className: `ButtonContainer ${props.className || ''}`
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vw;
  overflow: hidden;
  transition: 0.2s;
  opacity: ${props => props.active
    ? `1;`
    : `0;`}

  & > * {
    cursor: pointer;
    flex: 0 0 48%;
  }
`;
