import React, { useState, useRef } from "react";
import { formConfig } from './Form.config'
import ContactFormAPIClient from '../../../../api/clients/contactForm'
import {
  validateEmail,
  useLocation
} from '../../../Helpers'
import {
  Color,
  Padding,
  ContentContainer,
  Heading,
  FormContainer,
  InputLabel,
  // InputReqLabel,
  // REQ_TEXT,
  InputText,
  Button,
  MailSuccess,
  MailError,
  ReqMessage,
} from '../../../../styles'

export default function RequestQuoteFavs({
  title,
  content
}) {
  // state
  const [mailSuccess, setMailSuccess] = useState(false)
  const [mailFailed, setMailFailed] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const [formInputEmail, setFormInputEmail] = useState('')
  // refs
  const inputNameEl = useRef(null)
  const inputTitleEl = useRef(null)
  const inputCompanyEl = useRef(null)
  const inputEmailEl = useRef(null)
  const inputPhoneEl = useRef(null)
  const inputZipEl = useRef(null)
  const location = useLocation()
  // vars
  const inputBorderStyle = `1px solid ${Color.gray}`;
  const inputMarginStyle = `0 0 3% 0`;
  const inputPaddingStyle = `0.65em`;

  const handleFormSubmission = e => {
    // Prevent the default action
    e.preventDefault()
    e.stopPropagation()
    // disable btn
    setIsLoading(true)
    // set vars
    const nameContent = inputNameEl.current.value.replace(' ', '')
    const titleContent = inputTitleEl.current.value.replace(' ', '')
    const companyContent = inputCompanyEl.current.value.replace(' ', '')
    const emailContent = inputEmailEl.current.value.replace(' ', '')
    const phoneContent = inputPhoneEl.current.value.replace(' ', '')
    const zipContent = inputZipEl.current.value.replace(' ', '')
    const productsContent = content && content.map((prod, idx) => {
      return prod.title
    })

    if (
      '' !== nameContent
      && '' !== titleContent
      && '' !== companyContent
      && '' !== emailContent
      && '' !== phoneContent
      && '' !== zipContent
      && validateEmail(emailContent, false) // TODO - set corp email check to TRUE
    ) {
      submitForm({
        toClient: emailContent,
        subject: 'Request a Quote of Favorites',
        bodyClient: `Hi,<br><br>We have received the following quote request of favorites from you.<br><br><b>URL:</b> ${location.href}<br><b>Name:</b> ${nameContent}<br><b>Title:</b> ${titleContent}<br><b>Company:</b> ${companyContent}<br><b>Email:</b> ${emailContent}<br><b>Phone:</b> ${phoneContent}<br><b>ZIP:</b> ${zipContent}<br><b>Products:</b> ${productsContent.join(', ')}`,
        bodyAdmin: `Hi,<br><br>A quote request of favorites has been received.<br><br><b>URL:</b> ${location.href}<br><b>Name:</b> ${nameContent}<br><b>Title:</b> ${titleContent}<br><b>Company:</b> ${companyContent}<br><b>Email:</b> ${emailContent}<br><b>Phone:</b> ${phoneContent}<br><b>ZIP:</b> ${zipContent}<br><b>Products:</b> ${productsContent.join(', ')}`,
        url: location.href,
      })
    } else {
      setMailSuccess(false)
      setMailFailed(true)
      setIsLoading(false)
      setIsError(false)
    }
  }

  const submitForm = async (formData) => {
    try {
      // TODO - update to suit specific endpoint
      const API = new ContactFormAPIClient()
      const response = await API.submitContactForm(formData)
      // handle response if successful
      if (true === response.success) {
        setMailSuccess(true)
        setMailFailed(false)
        setIsError(false)
      } else {
        // TODO - test email response from API. Currently hardcoding success, if all fields are valid/filled....
        setMailSuccess(true)
        setMailFailed(false)
        setIsError(false)
        // correct states...
        // setMailSuccess(false)
        // setMailFailed(false)
        // setIsError(true)
      }
    } catch(err) {
      setMailSuccess(false)
      setMailFailed(false)
      setIsError(true)
      console.error(err)
    }
    setIsLoading(false)
  }

  return (<ContentContainer>
    <Heading
      type={'h4'}
      children={title}
      color={`blue`}
    />
    {(!isError && !mailSuccess)
      && <FormContainer
        action={'#'}
        padding={`${Padding.padding0} 0`}
      >
        <ReqMessage>
          {'All fields are required.'}
        </ReqMessage>
        <InputLabel>
          {formConfig.form_fields.name.label}
          {/* {formConfig.form_fields.name.required
            && <InputReqLabel>{REQ_TEXT}</InputReqLabel>} */}
        </InputLabel>
        <InputText
          ref={inputNameEl}
          name={formConfig.form_fields.name.name}
          margin={inputMarginStyle}
          padding={inputPaddingStyle}
          border={inputBorderStyle}
          required={formConfig.form_fields.name.required ? true : false}
        />
        <InputLabel>
          {formConfig.form_fields.title.label}
          {/* {formConfig.form_fields.title.required
            && <InputReqLabel>{REQ_TEXT}</InputReqLabel>} */}
        </InputLabel>
        <InputText
          ref={inputTitleEl}
          name={formConfig.form_fields.title.name}
          margin={inputMarginStyle}
          padding={inputPaddingStyle}
          border={inputBorderStyle}
          required={formConfig.form_fields.title.required ? true : false}
        />
        <InputLabel>
          {formConfig.form_fields.company.label}
          {/* {formConfig.form_fields.company.required
            && <InputReqLabel>{REQ_TEXT}</InputReqLabel>} */}
        </InputLabel>
        <InputText
          ref={inputCompanyEl}
          name={formConfig.form_fields.company.name}
          margin={inputMarginStyle}
          padding={inputPaddingStyle}
          border={inputBorderStyle}
          required={formConfig.form_fields.company.required ? true : false}
        />
        <InputLabel>
          {formConfig.form_fields.email.label}
          {/* {formConfig.form_fields.email.required
            && <InputReqLabel>{REQ_TEXT}</InputReqLabel>} */}
        </InputLabel>
        <InputText
          ref={inputEmailEl}
          name={formConfig.form_fields.email.name}
          type={'email'}
          margin={inputMarginStyle}
          padding={inputPaddingStyle}
          border={inputBorderStyle}
          required={formConfig.form_fields.name.required ? true : false}
        />
        <InputLabel>
          {formConfig.form_fields.phone.label}
          {/* {formConfig.form_fields.phone.required
            && <InputReqLabel>{REQ_TEXT}</InputReqLabel>} */}
        </InputLabel>
        <InputText
          ref={inputPhoneEl}
          name={formConfig.form_fields.phone.name}
          margin={inputMarginStyle}
          padding={inputPaddingStyle}
          border={inputBorderStyle}
          required={formConfig.form_fields.phone.required ? true : false}
        />
        <InputLabel>
          {formConfig.form_fields.zip.label}
          {/* {formConfig.form_fields.zip.required
            && <InputReqLabel>{REQ_TEXT}</InputReqLabel>} */}
        </InputLabel>
        <InputText
          ref={inputZipEl}
          name={formConfig.form_fields.zip.name}
          margin={inputMarginStyle}
          padding={inputPaddingStyle}
          border={inputBorderStyle}
          required={formConfig.form_fields.zip.required ? true : false}
        />
        {mailFailed === true &&
          <MailError>
            {formConfig.mailFail}
          </MailError>
        }
        <Button
          type={'submit'}
          onClick={(e) => handleFormSubmission(e)}
          disabled={isLoading}
        >
          {'Send'}
        </Button>
      </FormContainer>}
    {isError
      && <MailError>
        {formConfig.formError}
      </MailError>
    }
    {mailSuccess
      && <MailSuccess>
        {formConfig.mailSent}
      </MailSuccess>
    }
  </ContentContainer>);
}
