import React from "react";
import { Wrapper } from "./styles";

export default function Separator({
    margin
}) {
  return (
    <Wrapper margin={margin}>
      <hr />
    </Wrapper>
  );
}
