import styled from "styled-components";
import { Color, Widths } from "../../../../styles";

export const Wrapper = styled.div`
  background-image: linear-gradient(to right, #a4c5b0, #aaaacc, #ebc697);
  padding: 6rem 3rem 9rem 3rem;
`;

export const Container = styled.div`
  max-width: 1198px;
  margin-left: auto;
  margin-right: auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 3rem;

  @media screen and (min-width: ${Widths.minLaptop}) {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
`;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 14.5rem;
  align-items: center;
  margin: 0 auto;
`;

export const MainHeading = styled.div`
  text-align: center;

  @media screen and (min-width: ${Widths.minLaptop}) {
    h2 {
      font-size: 3.5em;
      margin-bottom: 4.5rem;
    }
    }
  }
`;

export const WrapImage = styled.div`
  height: 98px;
  margin-bottom: 1.5rem;
`;

export const Headline = styled.p`
  font-size: 2em;
  line-height: 1.5rem;
  font-weight: bold;
  color: ${Color.white};
  text-align: center;

  @media screen and (min-width: ${Widths.minLaptop}) {
    font-size: 2.2em;
    line-height: 1.75rem;
  }
`;
