import styled from "styled-components";
import { Color, Widths } from "../../../../styles";

export const Wrapper = styled.div`
  margin-bottom: 2rem;
  h3 {
    text-align: center;
  }
`;

export const Container = styled.div`
  max-width: 1198px;
  margin-left: auto;
  margin-right: auto;
`;

export const WrapQuote = styled.div`
  background: linear-gradient(
    89.88deg,
    #5881bc 1.09%,
    #aaaacc 53.48%,
    #5881bc 99.88%
  );
  border-radius: 1.5rem;
  padding: 2rem 4rem;

  @media screen and (min-width: ${Widths.minLaptop}) {
    padding: 2rem 6rem;
  }
`;

export const QuoteText = styled.div`
  font-size: 2.2em;
  line-height: 1.2em;
  color: ${Color.white};
  margin-bottom: 20px;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;

  &.fade-out {
    opacity: 0;
  }
  &.fade-in {
    opacity: 1;
  }

  min-height: 23rem;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${Widths.minLaptop}) {
    min-height: 21rem;
  }
`;

export const Dots = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const Dot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${Color.white};
  margin: 0 8px;
  cursor: pointer;

  &.active {
    background-color: ${Color.black};
  }
`;
