import styled from "styled-components";
import { Color, Widths } from "../../../../styles";

export const Wrapper = styled.div`
  padding: 4rem 3rem;
  background-color: ${Color.blue};
  margin-bottom: 1.5rem;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(12, 1fr);
  max-width: 1198px;
  margin-left: auto;
  margin-right: auto;
  gap: 2rem;

  @media screen and (min-width: ${Widths.minLaptop}) {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(12, 1fr);
    gap: 3rem;
  }
`;

export const GridTitle = styled.div`
  grid-column: 1;

  h3 {
    margin-bottom: 0px;
  }

  @media screen and (min-width: ${Widths.minLaptop}) {
    grid-column: 1 / span 3;
    line-height: 1.75rem;
  }
`;

export const Features = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media screen and (min-width: ${Widths.minTablet}) {
    display: grid;
    grid-column: 4 / span 9;
    flex-direction: row;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
  }
`;

export const Headline = styled.p`
font-weight: bold;
font-size: 1rem;
color: white;
line-height: 1.375rem;
`;

export const Copy = styled.p`
font-size: 1rem;
color: white;
line-height: 1.375rem;
`;

export const Item = styled.div`
display: flex;
gap: 1rem;
`;

export const WrapImage = styled.div`
flex-grow: 0;
flex-shrink: 0;
width: 3rem;
margin-top: 0.5rem;
`;