import React from "react";
import { useWindowSize } from "../../../Helpers";
import { GridContainer, GridItem, Heading, FontSize } from "../../../../styles";
import { Content, WrapTittle } from "./styles";

export default function ThreeColumnContent({
  theme,
  title,
  first,
  second,
  third,
  reduceMargins,
  centerTitle,
}) {
  // vars
  // const themeColor = 'light' === theme ? `white` : `black`
  const windowSize = useWindowSize();
  let gap = "3.33%";
  let columns = "30% 30% 30%";
  let rows = "auto";
  if ("mobile" === windowSize.currDevice) {
    gap = "1.5em";
    columns = "repeat(1,100%)";
    rows = "repeat(3,auto)";
  } else if ("tablet" === windowSize.currDevice) {
    gap = "1.5em";
    columns = "repeat(1,100%)";
    rows = "repeat(3,auto)";
  }

  return (
    <>
      {title && (
        <WrapTittle centerTitle={centerTitle}>
          <Heading
            type={"h5"}
            children={title || ""}
            color={`blue`}
            fontSize={`2.4em`}
            tTransform={`capitalize`}
          />
        </WrapTittle>
      )}
      <GridContainer
        columns={columns}
        gap={gap}
        rows={rows}
        padding={` ${reduceMargins ? "0" : "3vw"}  0 0 0`}
      >
        <GridItem>
          {first.content && (
            <Content
              tAlign={"left"}
              fontSize={FontSize.body}
              dangerouslySetInnerHTML={{
                __html: first.content,
              }}
            />
          )}
        </GridItem>
        <GridItem>
          {second.content && (
            <Content
              tAlign={"left"}
              fontSize={FontSize.body}
              dangerouslySetInnerHTML={{
                __html: second.content,
              }}
            />
          )}
        </GridItem>
        <GridItem>
          {third.content && (
            <Content
              tAlign={"left"}
              fontSize={FontSize.body}
              dangerouslySetInnerHTML={{
                __html: third.content,
              }}
            />
          )}
        </GridItem>
      </GridContainer>
    </>
  );
}
