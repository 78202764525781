import React from "react";
import FullWidthTemplate from "./FullWidthTemplate/FullWidthTemplate";
import BlogTemplate from "./BlogTemplate/BlogTemplate";
import PostTemplate from "./PostTemplate/PostTemplate";
import LeftSidebarTemplate from "./LeftSidebarTemplate/LeftSidebarTemplate";
import RightSidebarTemplate from "./RightSidebarTemplate/RightSidebarTemplate";
import ProductDetailTemplate from "./ProductDetailTemplate/ProductDetailTemplate";
import ProductCategoryTemplate from "./ProductCategoryTemplate/ProductCategoryTemplate";
import ProductFavoritesTemplate from "./ProductFavoritesTemplate/ProductFavoritesTemplate";
import SearchResultsTemplate from "./SearchResultsTemplate/SearchResultsTemplate";
import TechnicalFilesTemplate from "./TechnicalFilesTemplate/TechnicalFilesTemplate";
import MixedRightSidebarTemplate from "./MixedRightSidebarTemplate/MixedRightSidebarTemplate";

export default function TemplateFactory({
  title,
  bannerImage,
  bannerImageMobile,
  content,
  template,
  modules,
  sidebarModules,
  footerModules,
}) {
  switch (template) {
    default:
    case "full-width":
      // Full width template expects content, modules
      return (
        <FullWidthTemplate title={title} content={content} modules={modules} />
      );

    case "blog-template":
      // Full width template expects content, modules
      return <BlogTemplate title={title} posts={modules} />;

    case "post-template":
      // Full width template expects content, modules
      return <PostTemplate title={title} post={modules} />;

    case "left-sidebar":
      return (
        // left-side template expects content, modules
        <LeftSidebarTemplate
          title={title}
          content={content}
          modules={modules}
          sidebarModules={sidebarModules}
        />
      );

    case "right-sidebar":
      return (
        // Full width template expects content, modules
        <RightSidebarTemplate
          title={title}
          content={content}
          modules={modules}
          sidebarModules={sidebarModules}
        />
      );

    case "mixed_right-sidebar":
      return (
        // Mixed right-side width template expects content, modules mixed
        <MixedRightSidebarTemplate
          title={title}
          content={content}
          modules={modules}
          sidebarModules={sidebarModules}
          footerModules={footerModules}
        />
      );

    case "product-category":
      return (
        <ProductCategoryTemplate
          title={title}
          bannerImage={bannerImage}
          bannerImageMobile={bannerImageMobile}
          content={content}
        />
      );

    case "product-favorites":
      return <ProductFavoritesTemplate title={title} />;

    case "product-detail":
      return <ProductDetailTemplate title={title} content={content} />;

    case "search-results":
      return <SearchResultsTemplate title={title} content={content} />;

    case "technical-files":
      return <TechnicalFilesTemplate title={title} content={content} />;
  }
}
