import styled from "styled-components";

import { Color, Block } from "../../../../styles";

export const Content = styled(Block)`
  line-height: 1.4em;

  a {
    color: ${Color.red};
    text-decoration: none;
  }
  .aligncenter {
    text-align: center;
  }
  img.aligncenter {
    display: flex;
    margin: 0 auto 1rem;
  }
`;

export const WrapTittle = styled.div`
  text-align: ${(props) => (props.centerTitle ? "center" : "start")};
`;
