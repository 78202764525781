import React, { useEffect, useState } from "react";
import { Heading } from "../PageHero/styles";
import { Copy, Grid, Item, Wrapper } from "./styles";
import ProductsAPIClient from "../../../../api/clients/products";
import {
  arrEmpty,
  createMarkup,
  getThumbnail,
  mergeAndRemoveDuplicatesByKey,
} from "../../../Helpers";
import {
  AnchorButton,
  FontSize,
  ProductDescription,
  ProductTitle,
} from "../../../../styles";

export default function ProductGrid({
  title,
  products,
  productTypes,
  slimline = false,
  useShortDescription = false,
}) {
  const [gridProducts, setGridProducts] = useState(products || []);
  let allowedToLoad = true;

  useEffect(() => {
    const loadProducts = async () => {
      const productGroupIds = [];
      const productIds = [];
      let fetchedProducts = [];

      // gather required ids for API calls
      productTypes.forEach((productType) => {
        // get group ids
        productType.acf_fc_layout === "product_group" &&
          productGroupIds.push(productType.group);
        // get product ids
        productType.acf_fc_layout === "product_ids" &&
          productIds.push(productType.ids);
      });

      try {
        // instantiate API class
        const API = new ProductsAPIClient();

        // get prods by group ids
        if (!arrEmpty(productGroupIds)) {
          const response1 = await API.getProductsByGroupId(productGroupIds);
          if (true === response1.success && 0 < response1.products.length) {
            fetchedProducts = mergeAndRemoveDuplicatesByKey(
              fetchedProducts,
              response1.products,
              "id"
            );
          }
        }

        // get prods by ids
        if (!arrEmpty(productIds)) {
          const response2 = await API.getProductsById(productIds);
          if (true === response2.success && 0 < response2.products.length) {
            fetchedProducts = mergeAndRemoveDuplicatesByKey(
              fetchedProducts,
              response2.products,
              "id"
            );
          }
        }

        // set prods to state
        setGridProducts(fetchedProducts);
      } catch (error) {
        console.error(error);
        return [];
      }
    };

    allowedToLoad &&
      arrEmpty(gridProducts) && // products AREN'T provided
      !arrEmpty(productTypes) && // an array of product types ARE provided
      loadProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => (allowedToLoad = false);
  }, []);

  const renderCopy = (item) => {
    if (useShortDescription) {
      return (
        <ProductDescription
          dangerouslySetInnerHTML={createMarkup(
            item.sections?.product_details.short_description
          )}
        />
      );
    }

    return (
      <ProductDescription
        dangerouslySetInnerHTML={createMarkup(
          item.sections?.product_details?.long_description
        )}
      />
    );
  };

  return (
    <Wrapper>
      {title && <Heading type={"h2"} children={title} />}
      {gridProducts && (
        <Grid>
          {gridProducts.map((item, index) => {
            const prodImage = getThumbnail(item);
            return (
              <Item key={index}>
                <Copy>
                  <img src={prodImage} alt={item.title} />
                  {item.title && (
                    <ProductTitle
                      type={"h6"}
                      fontWeight={"800"}
                      children={item.title}
                    />
                  )}
                  {renderCopy(item)}
                </Copy>
                <AnchorButton
                  fontSize={FontSize.button.small}
                  padding={"1rem 2rem"}
                  href={item.canonical_url}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  maxWidth={"none"}
                  width={"auto"}
                  minWidth={"175px"}
                >
                  {`View ${item.title}`}
                </AnchorButton>
              </Item>
            );
          })}
        </Grid>
      )}
    </Wrapper>
  );
}
